import { StyleFunctionProps, transparentize } from "@chakra-ui/theme-tools";

/*
 * Use this file for setting global theme styles like the font(s),
 * base-text color, and the background color of the body.
 *
 * You can see the default values here:
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts
 */
const styles = {
  global: (props: StyleFunctionProps) => ({
    html: {
      WebkitFontSmoothing: "auto",
      MozOsxFontSmoothing: "auto",
    },
    "::selection": {
      background: transparentize("lightSoftBlue", 0.6)(props.theme),
    },
    "body > #date-picker .react-datepicker-popper": {
      zIndex: 100,
    },
  }),
};

export default styles;
