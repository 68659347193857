import { differenceInMilliseconds } from "date-fns";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef } from "react";
import { assertPresence } from "utils/assertions";
import { ANALYTICS_DASHBOARD } from "utils/links";
import useLatestRef from "utils/useLatestRef";

export const RETURN_TO_QUERY =
  "top_nav=true&side_nav=true&logo=false&additional_info=false";

export function getOrigin(isEmbed?: boolean) {
  if (typeof window === "undefined") return null;

  const { hostname } = window.location;

  const subdomain = isEmbed ? "analytics-embed" : "analytics";

  if (hostname.endsWith("rodeocpg-dev.net") || hostname === "localhost")
    return `https://${subdomain}.rodeocpg-dev.net`;

  return `https://${subdomain}.snobase.com`;
}

export function getIsInEmbed() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function getJwtAuthUri(origin: string, token: string, returnTo = "/") {
  return `${origin}/auth/sso?jwt=${token}&return_to=${encodeURIComponent(
    returnTo,
  )}`;
}

export type MetabaseMessageLocation = {
  type: "location";
  location: Location | string;
};

export type MetabaseMessageFrame = {
  type: "frame";
  frame: { mode: "normal" } | { mode: "fit"; height: number };
};

export type MetabaseMessage = MetabaseMessageLocation | MetabaseMessageFrame;

export function useOnMetabaseMessage(
  onMessage: (message: MetabaseMessage) => void,
) {
  const latestOnMessageRef = useLatestRef(onMessage);

  const origin = getOrigin(true);

  const listener = useCallback(
    (evt: MessageEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const msg = evt.data?.metabase as MetabaseMessage | undefined;
      if (evt.origin === origin && msg) {
        latestOnMessageRef.current(msg);
      }
    },
    [origin, latestOnMessageRef],
  );

  useEffect(() => {
    if (typeof window === "undefined") return;

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, [listener]);
}

export function getIsNextCancelledError(error: unknown) {
  return error instanceof Error && "cancelled" in error && error.cancelled;
}

function ignoreCancelledError(error: unknown) {
  if (!getIsNextCancelledError(error)) {
    throw error;
  }
}

type MetabaseNavigation = { path: string; date: Date };

export function useMetabaseLocation(iframeElement: HTMLIFrameElement | null) {
  const origin = getOrigin(true);
  const router = useRouter();

  const metabaseNavsRef = useRef<MetabaseNavigation[]>([]);

  const checkMetabaseNavigation = (path: string) => {
    const deleted = metabaseNavsRef.current.reduce<MetabaseNavigation[]>(
      (acc, nav, idx) => {
        const ms = differenceInMilliseconds(new Date(), nav.date);
        if (ms <= 500 && nav.path === path) {
          delete metabaseNavsRef.current[idx];
          acc.push(nav);
        } else if (ms > 5000) {
          delete metabaseNavsRef.current[idx];
        }
        return acc;
      },
      [],
    );
    return deleted.length === 0;
  };

  useOnMetabaseMessage((message) => {
    if (message.type === "location") {
      const path = (
        typeof message.location === "string"
          ? message.location
          : message.location.href
      )
        .replace(`${origin}`, "")
        .replace(RETURN_TO_QUERY, "")
        .replace(/[&?]$/, "");
      try {
        metabaseNavsRef.current.push({ path, date: new Date() });
        router
          .replace({ hash: `#${path}` }, undefined, { shallow: true })
          .catch((reason) => {
            ignoreCancelledError(reason);
          });
      } catch (e) {
        ignoreCancelledError(e);
      }
    }
  });

  const hashChangeListener = useCallback(
    (newPathname: string) => {
      if (!newPathname.startsWith(ANALYTICS_DASHBOARD)) return;
      if (!iframeElement?.contentWindow) return;
      assertPresence(origin);

      const metabasePathname = newPathname
        .replace(ANALYTICS_DASHBOARD, "")
        .replace(/^#/, "");

      if (!checkMetabaseNavigation(metabasePathname)) return;

      const msg: MetabaseMessageLocation = {
        type: "location",
        location: `${origin}${metabasePathname}`,
      };
      iframeElement.contentWindow.postMessage({ metabase: msg }, origin);
    },
    [origin, iframeElement],
  );

  useEffect(() => {
    router.events.on("hashChangeStart", hashChangeListener);
    return () => {
      router.events.off("hashChangeStart", hashChangeListener);
    };
  }, [router.events, hashChangeListener]);

  const initialHash = useRef(
    typeof window === "undefined" ? null : window.location.hash,
  );

  return initialHash.current?.replace(/^#/, "") || "/";
}

export function getReturnToPath(metabasePath: string) {
  const origin = getOrigin(true) || "https://analytics-embed.snobase.com/";
  const parsed = new URL(metabasePath, origin);
  const metabaseQueryStr = parsed.search?.trim()?.replace(/^\?/, "");
  const queryStr = metabaseQueryStr
    ? `${metabaseQueryStr}&${RETURN_TO_QUERY}`
    : RETURN_TO_QUERY;
  return `${parsed.pathname}?${queryStr}${parsed.hash}`;
}
