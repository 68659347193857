import NextLink, { LinkProps } from "next/link";
import { Box, Flex, FlexProps } from "@chakra-ui/react";
import Logo from "public/assets/snobase-wordmark.min.svg";

type Props = FlexProps & Partial<Pick<LinkProps, "href">>;

export default function NavBarLogo({ children, href = "/", ...rest }: Props) {
  const content = (
    <Flex
      id="NavBar-home-logo"
      as="a"
      padding="0.85rem 1rem"
      marginRight="1rem"
      marginBottom=".1em"
      alignItems="center"
      href={href}
      {...rest}
    >
      <Box as={Logo} w="7.25rem" />
      {children}
    </Flex>
  );

  if (typeof href === "string" && href.startsWith("http")) {
    return content;
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      {content}
    </NextLink>
  );
}
