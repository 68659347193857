import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { statAnatomy as parts } from "@chakra-ui/anatomy";

const msch = createMultiStyleConfigHelpers(parts.keys);

export function getLabeledValueStyles(isLarge = false) {
  return {
    label: {
      color: "magenta.dark",
      textTransform: "uppercase",
      fontSize: ".8em",
      fontWeight: 700,
    },
    number: {
      fontWeight: "inherit",
      fontSize: isLarge ? undefined : "1em",
    },
  };
}

export default msch.defineMultiStyleConfig({
  sizes: {
    lg: {
      label: { fontSize: "lg" },
      helpText: { fontSize: "lg" },
      number: { fontSize: "3xl" },
    },
    xl: {
      label: { fontSize: "lg" },
      helpText: { fontSize: "lg" },
      number: { fontSize: "4xl" },
    },
  },
  variants: {
    labeledValue: (props) => getLabeledValueStyles(!!props["data-large"]),
  },
});
