import { ReactElement } from "react";
import { NextPage } from "next";
import { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import Favicon from "components/Shared/Favicon";
import HeadTitle from "components/Shared/HeadTitle";
import ErrorBoundary from "components/Layout/ErrorBoundary";
import ToastUnhandledErrorWrapper from "components/Shared/ToastUnhandledError";
import { AuthProvider } from "utils/auth";
import { useSegmentTrackPage } from "utils/segment";
import PageAccessGate from "components/Layout/PageAccessGate";
import theme from "theme";
import client from "utils/apollo/client";
import useReleaseCheck from "utils/useReleaseCheck";
import { ToastContainer } from "utils/standaloneToast";
import NoAccountModal from "components/Layout/BrandSettings/NoAccountModal";

import "styles/global.css";
import "styles/react-datepicker.scss";

import "@fontsource/work-sans/300.css";

import "@fontsource-variable/figtree";

import "@fontsource/noto-sans-mono/300.css";
import "@fontsource/noto-sans-mono/400.css";

type NextPageWithLayout = NextPage & {
  /** Used to define the page layout that should be used when rendering this
   * page. */
  getLayout?: (page: ReactElement) => ReactElement;
  /** If `true`, users without a valid auth token will be redirected to the login page. */
  allowUnauthenticated?: boolean;
  /** If `true`, users with a valid auth token will be redirected to the app. */
  disallowAuthenticated?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  useSegmentTrackPage();
  useReleaseCheck();
  const { disallowAuthenticated = false, allowUnauthenticated = false } =
    Component;
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme} resetCSS>
        <ToastUnhandledErrorWrapper>
          <ErrorBoundary>
            <AuthProvider
              redirectAuthenticated={disallowAuthenticated}
              redirectUnauthenticated={!allowUnauthenticated}
            >
              <Favicon />
              <HeadTitle />

              <PageAccessGate>
                {getLayout(<Component {...pageProps} />)}
              </PageAccessGate>

              <NoAccountModal />
            </AuthProvider>
          </ErrorBoundary>
        </ToastUnhandledErrorWrapper>
        <ToastContainer />
      </ChakraProvider>
    </ApolloProvider>
  );
}
