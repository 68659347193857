import { compact } from "lodash";
import Head from "next/head";
import { APP_NAME } from "utils/config";

export const BASE_TITLE = APP_NAME;

export const TITLE_SEPARATOR = "|";

export function getTitleFromParts(parts: (string | null | undefined)[]) {
  return compact(parts).join(` ${TITLE_SEPARATOR} `);
}

type Props = {
  title?: string | string[] | null;
};

export function makeHeadTitleComponent(base = BASE_TITLE) {
  // eslint-disable-next-line react/function-component-definition
  return function HeadTitle({ title }: Props) {
    const titleArray = title && Array.isArray(title) ? title : [title];
    return (
      <Head>
        <title>{getTitleFromParts([...titleArray, base])}</title>
      </Head>
    );
  };
}

const HeadTitle = makeHeadTitleComponent();

export default HeadTitle;
