/* eslint-disable max-classes-per-file */
/* eslint-disable import/prefer-default-export */

import { useEffect } from "react";
import useLatestRef from "utils/useLatestRef";

interface Parser<T> {
  parse(value: string): T;
  stringify(value: T): string;
}

const defaultParser: Parser<string> = {
  parse: (v) => v,
  stringify: (v) => v,
};

export class LocalStorageKey<T = string, P extends Parser<T> = Parser<T>> {
  public readonly key: string;

  private readonly parser: P;

  public readonly namespace: string;

  constructor(
    key: string,
    parser: P = defaultParser as P,
    namespace = "rodeocpg:",
  ) {
    this.key = key;
    this.parser = parser;
    this.namespace = namespace;
  }

  get namespacedKey() {
    return `${this.namespace}${this.key}`;
  }

  get() {
    if (typeof window === "undefined") return;
    const raw = window.localStorage.getItem(this.namespacedKey);
    if (raw === null) return null;
    return this.parser.parse(raw);
  }

  set(value: T) {
    if (typeof window === "undefined") return;
    const strVal = this.parser.stringify(value);
    return window.localStorage.setItem(this.namespacedKey, strVal);
  }

  remove() {
    if (typeof window === "undefined") return;
    return window.localStorage.removeItem(this.namespacedKey);
  }
}

export class LocalStorageKeyJSON<T> extends LocalStorageKey<T> {
  constructor(key: string, namespace?: string) {
    super(key, JSON, namespace);
  }
}

const defaultBooleanParser: Parser<boolean | null> = {
  parse: (v) => {
    if (v === "true") return true;
    if (v === "false") return false;
    return null;
  },
  stringify: (v) => String(v),
};

export class LocalStorageKeyBoolean extends LocalStorageKey<boolean | null> {
  constructor(key: string, namespace?: string) {
    super(key, defaultBooleanParser, namespace);
  }
}

export function useSyncLocalStorage<T>(
  instance: LocalStorageKey<T>,
  value: T | undefined | null,
  setFromLocalStorage: (data: T) => void,
) {
  const latestInstanceRef = useLatestRef(instance);
  const latestSetFromLocalStorage = useLatestRef(setFromLocalStorage);

  useEffect(() => {
    const dataFromLs = latestInstanceRef.current.get();
    if (dataFromLs) latestSetFromLocalStorage.current(dataFromLs);
  }, [latestInstanceRef, latestSetFromLocalStorage]);

  useEffect(() => {
    if (value) {
      latestInstanceRef.current.set(value);
    } else {
      latestInstanceRef.current.remove();
    }
  }, [value, latestInstanceRef]);
}
