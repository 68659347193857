/*
 *
 * Typography
 *
 * Use this file for customizing the theme's fonts and other typography values.
 *
 * You can see the default values here:
 * https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/typography.ts
 */

const typography = {
  fonts: {
    body: "'Figtree Variable', sans-serif",
    heading: "'Figtree Variable', sans-serif",
    mono: "'Noto Sans Mono', Menlo, monospace",
  },
};

export default typography;
