import {
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import type { SelectInstance } from "react-select";
import { useQuery } from "utils/apollo/hooks";
import {
  useCurrentBrandId,
  useCurrentUserData,
  useCurrentUserId,
} from "utils/currentUserHooks";
import { gql } from "__generated__/gql";
import useCombineFilters from "utils/useCombineFilters";
import { BrandFilter } from "__generated__/gql/graphql";
import SwitchAccountSelect from "./Select";

const VALID_SUB_FILTER: BrandFilter = {
  isSubscriptionValid: { equalTo: true },
};

const query = gql(/* GraphQL */ `
  query SwitchAccountInfo($filter: BrandFilter) {
    brands(filter: $filter, orderBy: NAME_ASC, first: 5) {
      totalCount
      nodes {
        id
        name
        website
      }
    }
  }
`);

type Props = {
  // eslint-disable-next-line react/boolean-prop-naming
  autoFocus?: boolean;
  filter?: BrandFilter;
  initialFocusRef?: MutableRefObject<{ focus: () => void }>;
  isIncludeInactiveShown?: boolean;
  onBrandSelect: (brandId: string | null) => void;
};

export default function SwitchAccountModalContent({
  autoFocus = true,
  filter: extraFilter,
  initialFocusRef,
  isIncludeInactiveShown,
  onBrandSelect,
}: Props) {
  const currentUser = useCurrentUserData();
  const currentUserId = useCurrentUserId();
  const currentBrandId = useCurrentBrandId();

  const [isInactiveIncl, setIsInactiveIncl] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectRef = useRef<SelectInstance<any>>(null);

  const filter = useCombineFilters<BrandFilter>(
    useMemo(() => {
      if (!currentUserId) return null;

      const brandUsersFilter = {
        brandUsers: { some: { userId: { equalTo: currentUserId } } },
      };

      return { or: [brandUsersFilter, { parent: brandUsersFilter }] };
    }, [currentUserId]),
    extraFilter,
  );

  const selectFilter = useCombineFilters<BrandFilter>(
    isInactiveIncl ? null : VALID_SUB_FILTER,
    extraFilter,
  );

  const { data, loading } = useQuery(query, {
    skip: !currentUserId,
    variables: currentUserId && filter ? { filter } : undefined,
  });

  const numMyBrands = data?.brands?.totalCount;
  const isMyBrandsShown =
    typeof numMyBrands === "number"
      ? numMyBrands > 0 && numMyBrands <= 5
      : numMyBrands;

  const isSelectShown =
    currentUser?.canAccessAllBrands || isMyBrandsShown === false;

  const focus = useCallback(() => {
    if (isSelectShown) {
      selectRef.current?.focus();
    } else if (isMyBrandsShown) {
      buttonRef.current?.focus();
    }
  }, [isSelectShown, isMyBrandsShown]);

  useImperativeHandle(initialFocusRef, () => ({ focus }));

  useEffect(() => {
    if (autoFocus) focus();
  }, [autoFocus, focus]);

  return (
    <>
      {(!currentUser?.canAccessAllBrands || isMyBrandsShown) && (
        <Heading as="h2" size="sm" color="magenta.veryDark" mb={2}>
          My Brands
        </Heading>
      )}

      {loading && (
        <Spinner size="xl" color="gray" my={4} mx="auto" display="block" />
      )}

      {isMyBrandsShown &&
        data?.brands?.nodes.map((brand, idx) => (
          <Button
            key={brand.id}
            ref={idx === 0 ? buttonRef : undefined}
            autoFocus={(autoFocus && !isSelectShown && idx === 0) || undefined}
            variant="outline"
            borderRadius="md"
            w="100%"
            my={1}
            textTransform="none"
            whiteSpace="normal"
            onClick={() => onBrandSelect(brand.id)}
            aria-selected={brand.id === currentBrandId}
            _selected={{ bg: "lightPink", color: "white", fontWeight: 600 }}
          >
            {brand.name}
          </Button>
        ))}

      {currentUser?.canAccessAllBrands && (
        <Flex
          mb={2}
          sx={{ "button + &": { mt: 6 } }}
          justifyContent="space-between"
          alignItems="center"
          gap={4}
        >
          <Heading as="h2" size="sm" color="magenta.veryDark">
            All Brands
          </Heading>

          {isIncludeInactiveShown && (
            <Tooltip
              label="Check to include brands that do not have a valid subscription in search."
              placement="top-end"
              openDelay={250}
              hasArrow
            >
              <Box>
                <Checkbox
                  size="sm"
                  isChecked={isInactiveIncl}
                  onChange={(evt) => setIsInactiveIncl(evt.target.checked)}
                  color="gray.500"
                >
                  Include Inactive?
                </Checkbox>
              </Box>
            </Tooltip>
          )}
        </Flex>
      )}

      {isSelectShown && (
        <SwitchAccountSelect
          onBrandChange={onBrandSelect}
          filter={selectFilter}
          autoFocus={autoFocus}
          ref={selectRef}
        />
      )}
    </>
  );
}
