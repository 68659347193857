import Head from "next/head";

export default function Favicon() {
  return (
    <Head>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=3"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png?v=3"
      />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg?v=3" />
      <link rel="manifest" href="/site.webmanifest?v=3" />
      <link rel="icon" href="/favicon.ico?v=3" />
      <meta name="apple-mobile-web-app-title" content="Snobase" />
      <meta name="application-name" content="Snobase" />
      <meta name="msapplication-TileColor" content="#9f00a7" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
}
