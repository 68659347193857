import { useMemo } from "react";

export function optionalCombineFilters<T>(
  ...filters: (T | (T | undefined | null)[] | undefined | null)[]
) {
  const combined = filters.reduce<T[]>((acc, filter) => {
    if (!filter) return acc;

    if (Array.isArray(filter)) {
      filter.forEach((f) => {
        if (f) acc.push(f);
      });
    } else {
      acc.push(filter);
    }
    return acc;
  }, []);

  if (!combined.length) return;

  if (combined.length === 1) return combined[0];

  return { and: combined };
}

export function useOptionalCombineFilters<T>(
  ...filters: (T | (T | undefined | null)[] | undefined | null)[]
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => optionalCombineFilters(...filters), [...filters]);
}

export function combineFilters<T>(
  ...filters: (T | (T | undefined | null)[] | undefined | null)[]
) {
  const combined = optionalCombineFilters<T>(...filters);
  if (!combined) {
    return { and: [] as T[] };
  }
  return combined;
}

export default function useCombineFilters<T>(
  ...filters: (T | (T | undefined | null)[] | undefined | null)[]
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => combineFilters(...filters), [...filters]);
}
