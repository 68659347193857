import { Box, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { ReactElement } from "react";
import ErrorPage from "components/Shared/ErrorPage";
import { useAuth } from "utils/auth";
import { ADMIN_INDEX } from "utils/links";
import useAuthToken from "utils/useAuthToken";
import HomeButton from "components/Shared/HomeButton";

type Props = {
  children: ReactElement;
};

export default function PageAccessGate({ children }: Props) {
  const { pathname } = useRouter();
  const { isAuthenticated } = useAuth();
  const token = useAuthToken();

  if (!pathname.startsWith(ADMIN_INDEX) || !isAuthenticated) return children;

  const { isStaff } = token || {};

  if (isStaff !== false) return children;

  return (
    <ErrorPage heading="Access Denied">
      <Text fontSize="xl" fontWeight="700" mt={10}>
        You are attempting to access a page that requires different privileges
        than it appears you have.
      </Text>

      <Box mt={10}>
        <HomeButton />
      </Box>
    </ErrorPage>
  );
}
