import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { MutationTuple, useApolloClient, useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import * as authQueries from "utils/authQueries";
import RedirectingOverlay from "components/Core/Auth/RedirectingOverlay";
import {
  GetTokenMutation,
  GetTokenMutationVariables,
} from "__generated__/serverGql/graphql";
import tokenService, { AUTH_STATUS_CHANGE_EVENT } from "./TokenService";
import { LocalStorageKey } from "./localStorage";
import { clearCurrentUser } from "./sentryContext";
import serverClient from "./apollo/serverClient";
import { trackEvent } from "./segment";

/** This hook listens to `TokenService` events to sync authentication status
 * with React. */
function useIsAuthenticated() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    tokenService.isAuthenticated,
  );

  useEffect(() => {
    const listener = () => {
      if (tokenService.isAuthenticated !== isAuthenticated)
        setIsAuthenticated(tokenService.isAuthenticated);
    };
    tokenService.addEventListener(AUTH_STATUS_CHANGE_EVENT, listener);
    return () => {
      tokenService.removeEventListener(AUTH_STATUS_CHANGE_EVENT, listener);
    };
  }, [isAuthenticated]);

  return isAuthenticated;
}

enum RedirectingTo {
  App,
  Login,
}

const REDIRECTING_TO_PATHS: Record<RedirectingTo, string> = {
  [RedirectingTo.App]: "/",
  [RedirectingTo.Login]: "/login",
};

const DEFAULT_POST_LOGIN_PATH = RedirectingTo.App;

export const brandIdLocalStorage = new LocalStorageKey<UUID>("brandId");

interface AuthContext {
  currentBrandId: string | null;
  handleLogin: (
    data: GetTokenMutation,
    options?: UseLoginOptions,
  ) => Promise<void>;
  handleLogout: () => Promise<void>;
  isAuthenticated: boolean;
  setCurrentBrandId: (brandId: string | null) => void;
}

const authContext = createContext<AuthContext>({
  currentBrandId: brandIdLocalStorage.get() || null,
  handleLogin: () => Promise.reject(new Error("Not initialized")),
  handleLogout: () => Promise.reject(new Error("Not initialized")),
  isAuthenticated: false,
  setCurrentBrandId: () => {},
});

interface AuthProviderProps {
  /* eslint-disable react/boolean-prop-naming */
  /** If `true`, users with a valid auth token will be redirected to the app. */
  redirectAuthenticated?: boolean;
  /** If `true`, users without a valid auth token will be redirected to the login page. */
  redirectUnauthenticated?: boolean;
  /* eslint-enable react/boolean-prop-naming */
  children: React.ReactNode;
}

export function AuthProvider({
  redirectAuthenticated = false,
  redirectUnauthenticated = false,
  children,
}: AuthProviderProps) {
  const router = useRouter();
  const client = useApolloClient();
  const isAuthenticated = useIsAuthenticated();
  const [redirectingTo, setRedirectingTo] = useState<RedirectingTo | null>(
    null,
  );
  const redirectingToRef = useRef<RedirectingTo | null>(null);
  const [currentBrandId, setCurrentBrandId] = useState<string | null>(
    brandIdLocalStorage.get() || null,
  );
  const skipNextRedirectRef = useRef(false);

  const { push, replace, query } = router;

  const redirectTo = useCallback(
    async (to: RedirectingTo, path?: string) => {
      if (redirectingToRef.current === to) return;

      console.log(`redirecting to ${to}`);

      redirectingToRef.current = to;
      setRedirectingTo(to);

      const redirectToPath = path || REDIRECTING_TO_PATHS[to];

      const redirectToUrl = (() => {
        if (to !== RedirectingTo.Login || typeof window === "undefined") {
          return redirectToPath;
        }

        const nextPath = window.location.pathname;

        if (
          !nextPath ||
          nextPath === REDIRECTING_TO_PATHS[RedirectingTo.Login] ||
          nextPath === redirectToPath ||
          nextPath === REDIRECTING_TO_PATHS[DEFAULT_POST_LOGIN_PATH]
        )
          return redirectToPath;

        return {
          pathname: redirectToPath,
          query: {
            next: nextPath + window.location.search + window.location.hash,
          },
        };
      })();

      await replace(redirectToUrl);
      redirectingToRef.current = null;
      setRedirectingTo(null);
    },
    [replace],
  );

  const nextPathFromQuery =
    typeof query?.next === "string" &&
    query.next !== REDIRECTING_TO_PATHS[RedirectingTo.Login] &&
    query.next.startsWith("/")
      ? query.next
      : undefined;

  const handleLogin: AuthContext["handleLogin"] = useCallback(
    async (data, { redirectPath: redirectPathOption } = {}) => {
      if (!data.auth?.token) {
        throw new Error("Login completed but didn't return a `token`.");
      }

      const { token, refreshToken, refreshExpiresIn } = data.auth;

      skipNextRedirectRef.current = true;
      tokenService.token = token;
      tokenService.setRefreshToken(refreshToken, refreshExpiresIn);

      trackEvent("Signed In");

      const redirectPath = (() => {
        if (typeof redirectPathOption !== "undefined")
          return redirectPathOption;
        if (nextPathFromQuery) return nextPathFromQuery;
        return REDIRECTING_TO_PATHS[DEFAULT_POST_LOGIN_PATH];
      })();

      if (redirectPath) await replace(redirectPath);
    },
    [replace, nextPathFromQuery],
  );

  const handleLogout = useCallback(async () => {
    trackEvent("Signed Out");
    await tokenService.revokeToken();
    skipNextRedirectRef.current = true;
    tokenService.setRefreshToken(undefined);
    tokenService.token = undefined;
    clearCurrentUser();
    await push("/login");
    await client.clearStore();
  }, [push, client]);

  useEffect(() => {
    // Try to get a token from an existing refresh token, if available. If this
    // fails, we'll assume login is required.
    tokenService.getOrRefreshToken().catch(() => {});
  }, []);

  useEffect(() => {
    if (skipNextRedirectRef.current) {
      skipNextRedirectRef.current = false;
      return;
    }

    if (redirectAuthenticated && redirectUnauthenticated) {
      throw new Error(
        "Cannot redirect both authenticated and unauthenticated users!",
      );
    }

    // Redirect to / if the user is signed in
    if (redirectAuthenticated && isAuthenticated) {
      redirectTo(RedirectingTo.App, nextPathFromQuery).catch(() => {});
    }

    // Redirect to /login if the user isn't signed in
    if (redirectUnauthenticated && !isAuthenticated) {
      redirectTo(RedirectingTo.Login).catch(() => {});
    }
  }, [
    isAuthenticated,
    redirectTo,
    redirectAuthenticated,
    redirectUnauthenticated,
    nextPathFromQuery,
  ]);

  const context = useMemo(
    () => ({
      currentBrandId,
      handleLogin,
      handleLogout,
      isAuthenticated,
      setCurrentBrandId,
    }),
    [currentBrandId, handleLogin, handleLogout, isAuthenticated],
  );

  return (
    <authContext.Provider value={context}>
      {children}
      <RedirectingOverlay
        isOpen={!!redirectingTo}
        header={`Redirecting to ${
          redirectingTo === RedirectingTo.Login ? "Login" : "App"
        }`}
      >
        {redirectingTo === RedirectingTo.Login &&
          "You must be logged in to access this page."}
      </RedirectingOverlay>
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

type UseLoginOptions = {
  /** The path to which to redirect upon successful login. */
  redirectPath?: string | null;
};

function useIsMounted() {
  const mounted = useRef(false);
  const getIsMounted = useCallback(() => mounted.current, []);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return getIsMounted;
}

type LoginResult = {
  /** Will be `true` from the start of the token request, through to the
   * completion of login. */
  isLoading: boolean;
  /** Will be `true` from the successful completion of the token request through
   * to the completion of login. */
  isLoggingIn: boolean;
};

export function useLogin(
  loginOptions?: UseLoginOptions,
): [
  ...MutationTuple<GetTokenMutation, GetTokenMutationVariables>,
  LoginResult,
] {
  const { handleLogin } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [isLoggingIn, setLoggingIn] = useState(false);
  const getIsMounted = useIsMounted();

  const [loginMutation, result] = useMutation(authQueries.GET_TOKEN, {
    client: serverClient,
    onError: () => {
      setLoggingIn(false);
    },
  });

  const login = async (options?: Parameters<typeof loginMutation>[0]) => {
    setLoading(true);

    const response = await loginMutation(options);

    const { data, errors } = response;

    if (!data || errors) {
      setLoading(false);
      return response;
    }

    setLoggingIn(true);

    await handleLogin(data, loginOptions);

    if (getIsMounted()) {
      setLoading(false);
      setLoggingIn(false);
    }

    return response;
  };

  return [login, result, { isLoading, isLoggingIn }];
}

export function useLogout(): [() => Promise<void>, { loading: boolean }] {
  const { handleLogout: performLogout } = useAuth();
  const [loading, setLoading] = useState(false);
  const getIsMounted = useIsMounted();

  const logout = async () => {
    setLoading(true);
    const result = await performLogout();
    if (getIsMounted()) setLoading(false);
    return result;
  };

  return [logout, { loading }];
}
