import { ChevronRightIcon } from "@chakra-ui/icons";
import { useCurrentBrandData } from "utils/currentUserHooks";
import { BRAND_LAYOUT_HOMEPAGES } from "components/Layout/brandLayoutUtils";
import { BRAND_INDEX } from "utils/links";
import LinkButton from "components/Shared/LinkButton";

export default function HomeButton() {
  const brand = useCurrentBrandData();
  return (
    <LinkButton
      href={brand?.layout ? BRAND_LAYOUT_HOMEPAGES[brand.layout] : BRAND_INDEX}
      mt={14}
      colorScheme="lightPinkButton"
      rightIcon={<ChevronRightIcon boxSize="1.25em" mr="-.75em" />}
    >
      Take Me Home
    </LinkButton>
  );
}
