/* eslint-disable import/prefer-default-export */

export const SERVER_PREFIX = "/server";
export const GQL_PREFIX = "/gql";

export const APP_NAME = "SnoBase";
export const LANDING_PAGE_URL = "https://www.snobase.com/";
export const CONTACT_EMAIL = "hello@snobase.com";
export const CONTACT_PAGE = `${LANDING_PAGE_URL}contact` as const;

export const TERMS_LINK =
  "https://www.websitepolicies.com/policies/view/SWWL6xuh";
