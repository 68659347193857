import { ReactNode } from "react";
import { Heading, Container, Box, Flex, Center } from "@chakra-ui/react";
import Mountains from "public/assets/mountains.min.svg";
import {
  CONTENT_FULL_HEIGHT,
  NAVBAR_HEIGHT,
} from "components/Layout/constants";
import Logo from "components/Layout/NavBar/Logo";

export type Props = {
  children?: ReactNode;
  heading?: ReactNode;
  isWithNavBar?: boolean;
};

function ErrorPage({ children, heading, isWithNavBar }: Props) {
  return (
    <Box backgroundColor="lightBlueGrey">
      {!isWithNavBar && (
        <Flex height={NAVBAR_HEIGHT}>
          <Logo />
        </Flex>
      )}
      <Container
        as={Center}
        minH={CONTENT_FULL_HEIGHT}
        textAlign="center"
        color="magenta.veryDark"
        pos="relative"
      >
        <Box>
          <Box as={Mountains} width="15rem" margin="0 auto" mb={8} />
          {heading && <Heading fontSize="4xl">{heading}</Heading>}
          {children}
        </Box>
      </Container>
    </Box>
  );
}

export default ErrorPage;
